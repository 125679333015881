import store from "@/store";

const state = {
	// is_super: 1,
	// tenant_id:'5d157dc96d097'
	// 用户信息
	userInfo: {}
};
const mutations = {
	// 初始化登录状态
	initUser(state) {
		console.log('user initUser')
		let userInfo = sessionStorage.getItem('adminUserInfo')
		if (userInfo) {
			userInfo = JSON.parse(userInfo)
			state.userInfo = userInfo
		}
	},
	setInfo(state, res) {
		console.log('user setInfo', res)
		state.userInfo = res
		sessionStorage.setItem('adminUserInfo', JSON.stringify(res))
	},
    logout(state) {
        state.userInfo = {}
        sessionStorage.removeItem('adminUserInfo')
    },
	getUserMsgCount(state, res) {
		console.log('user getUserMsgCount', res)
		//如果已经登录，请求获取用户消息数量
		if (state.userInfo && state.userInfo.token) {
			axios.post('/api/Message/index', {
				token: state.userInfo.token
			}).then(res => {
				if (res.status === 1) {
					let result = res.result
					state.userInfo.msgCount = 0
					var arr = []
					for (let item in result) {
						arr.push(result[item])
					}
					let unread = 0
					for (var i = 0; i < arr.length; i++) {
						unread += arr[i].unread
					}
					state.userInfo.msgCount = unread
					sessionStorage.setItem('adminUserInfo', JSON.stringify(state.userInfo))
				}
			})
		}
	}
};
const actions = {

};
const getters = {

};
export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters
}
