import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
    {
        path: '/login',
        name: 'login',
        component: () => import('../views/login/login.vue'),
        meta: {
            title: '登录'
        }
    },
    {
        path: '/',
        name: 'index',
        component: () => import('../views/index/index.vue'),
        meta: {
            title: '159吃瓜网'
        }
    },
    {
        path: '/detail',
        name: 'detail',
        component: () => import('../views/index/detail.vue'),
        meta: {
            title: '159吃瓜 - 详情'
        }
    },
]

const router = new VueRouter({
    mode: 'hash',
    base: '/user',
    routes
})

export default router
