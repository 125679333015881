<template>
    <div id="app">
        <router-view />
        <cgFooter />
    </div>
</template>

<script>
import cgHeader from '@/components/cgHeader.vue'
import cgFooter from '@/components/cgFooter.vue'
export default {
    name: 'app',
    components: {
        cgHeader,
        cgFooter
    },
    methods: {},
    mounted() {
        // this.$store.commit('user/initUser')
    },
    created() {
    },
    beforeDestroy() {
    }
}
</script>

<style lang="scss">
*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html,#app{
    margin: 0;
    padding: 0;
    height: 100%;
    background: #2c2a2a;
}

body{
  background: #2c2a2a;
}

.login{
    .el-input__inner {
        border: 0 !important;
    }
}

.el-button--primary{
    background: #d81d06 !important;
    border-color: #d81d06 !important;
}
.el-pagination{
	text-align: center;
}
.el-pagination .btn-next, .el-pagination .btn-prev{
	color: #fff!important;
}
.search {
	display: flex;
	box-sizing: border-box;
	// overflow: hidden;
	background-color: #2c2a2a;
	border-radius: 20px;
	flex-shrink: 0;
	font-size: unset!important;
	.el-input__inner{
		height: .4rem;
		line-height: .4rem;
		border: 0;
		background-color: transparent;
		border-color: #323030;
		color: #fff;
		font-size: .14rem;
		// border-bottom: 1px solid #fff;
		
		@media screen and (max-width: 1199px) {
			height: .56rem;
			line-height: .56rem;
			font-size: .24rem;
		}
	}
	.el-input-group__append{
		width: .4rem;
		padding: 0;
		margin: 0;
		border: 0;
		border-radius: 0;
		border-radius: 1000px;
		background-color: transparent;
		
		@media screen and (max-width: 1199px) {
			width: .56rem;
		}
		
		&:hover{
			background-color: #fff;
		}
	}
	.el-input.is-active .el-input__inner, .el-input__inner:focus{
		border: 0;
	}
	.el-input-group__append .el-button, .el-input-group__append .el-select, .el-input-group__prepend .el-button, .el-input-group__prepend .el-select{
		width: .4rem;
		margin: 0;
		padding: 0;
	}
}

.mobile-header{
	.search{
		height: 2rem;
		margin-top: .4rem;
		.el-input__inner{
			height: 2rem;
			font-size: 1rem;
		}
		.el-input-group__append{
			width: 2rem;
		}
	}
}

.el-pager li{
	background-color: transparent!important;
}
.el-pagination .btn-prev,.el-pagination .btn-next{
	background-color: transparent!important;
	color: #fff;
}

.el-pager li{
	color: #fff!important;
}

.el-pager li.active{
	color: rgba(255, 255, 255, .5)!important;
}
</style>
